import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import StreamsComponent from "../components/streams-component"


const StreamsPage = () => (
  <div>
    <StreamsComponent></StreamsComponent>
  </div>
)

export default StreamsPage
